// @import "@angular/cdk/overlay-prebuilt.css";
// @import '@angular/cdk/overlay-prebuilt.css';
@import "themes/base";
// @import url("~normalize.css");
// @import "./themes/bootstrap-custom.scss";

@font-face {
  font-family: "Moul";
  src: url("./assets/fonts/Moul-Regular.ttf");
}

body {
  margin: 0;
}

.mat-raised-button,
.mat-button,
.mat-stroked-button,
.mat-flat-button {
  border-radius: 4px;

  &.rounded {
    border-radius: 100px;
  }
}

h1,h2,h3,h4,h5,h6,p {
  font-family: $fontFamily !important;
}

mat-label {
  font-family: $fontFamily !important;
}

simple-snack-bar span {
  // font-family: "SegoeUI", "KhmerOSbattambang" !important;
  margin: auto;
  text-align: center;
  color: #eee;
}

.panelClass-success {
  background: #04b160;
  color: white;
  text-align: center;
}

.panelClass-error {
  background: #ff4949;
  color: white;
  text-align: center;
}
.mat-snack-bar-container {
  &.action-snackbar {
    padding: 6px 16px;
  }
}

button {
  outline: none;
}

.mat-toolbar {
  background: white;
  // min-height: 65px;
  // @include light-drop-shadow;
}

.spacer {
  flex: 1 1 auto;
}

.container.fixed {
  max-width: 100%;
  margin: 0px;
  background-color: #ececec;
  overflow-y: scroll;
}

.mat-form-field-label {
  padding: 4px 0;
  height: 20px;
}

.mat-form-field {
  input.mat-input-element {
    line-height: 2;
  }
}

.mat-select-value {
  line-height: 2;
}

textarea.mat-input-element {
  line-height: 28px;
}

.no-border .mat-form-field-infix {
  border: none;
}

.loading-layout {
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(white, 0.8);
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.cdk-overlay-backdrop.cdk-overlay-dark-backdrop {
  background: rgba($primaryColor, 0.9);
}

.less-shadow {
  .mat-dialog-container {
    box-shadow: 0 2px 6px rgba(black, 0.1);
    border-radius: 6px;
  }

  .mat-selection-list {
    outline: none;
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

::-moz-selection {
  color: white;
  background: $accentColor;
}

::selection {
  color: white;
  background: $accentColor;
}

.mat-search {
  overflow: hidden;
  flex: 1 1 auto;
  // padding: 4px;
  display: flex;

  .search-input {
    display: inline-block;
    position: relative;
    input {
      margin: 0 10px;
      border-radius: 6px;
      flex-grow: 1;
      color: #333;
      background: rgba(black, 0.05);
      font-size: 0.8em;
      font-weight: normal;
      padding: 0 14px;
      height: 45px;
      line-height: 45px;
      outline: none;
      border: 1px solid transparent;
      transition: 0.1s ease;
      max-width: 500px;
      width: 350px;
      padding-right: 50px;

      &:focus {
        background: white;
        box-shadow: 0 1px 2px rgba(black, 0.05);
        border: 1px solid rgba(black, 0.05);
      }
    }

    button {
      position: absolute;
      right: 10px;
      top: 5px;
    }
  }

  input {
    // margin: 0 10px;
    // border-radius: 6px;
    // flex-grow: 1;
    // color: #333;
    // background: rgba(black, 0.05);
    // font-size: 0.8em;
    // font-weight: normal;
    // padding: 0 14px;
    height: 45px;
    line-height: 45px;
    // outline: none;
    // border: 1px solid transparent;
    // transition: 0.1s ease;
    // max-width: 500px;
    // width: 350px;
    // padding-right: 50px;

    // &:focus {
    //   background: white;
    //   box-shadow: 0 1px 2px rgba(black, 0.05);
    //   border: 1px solid rgba(black, 0.05);
    // }
  }
}

.common-card {
  border-radius: 6px;
  overflow: hidden;

  box-shadow: 0 1px 2px rgba(black, 0.1);
  background: white;

  .title {
    color: #062743;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: block;
  }

  .subtitle {
    color: #8f8f8f;
  }
}

.icon-text {
  margin: 2px 0;
  display: flex;
  align-items: center;

  span {
    margin-left: 6px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: block;
  }

  &.warn {
    color: #ff8a14;
  }
}

.mat-fab.fixed {
  position: fixed;
  right: 50px;
  bottom: 30px;
}

.mat-toolbar-multiple-rows {
  z-index: 10;
}

.tree-dialog-panel {
  background: white;
}

//custom mat-menu
.mat-menu-panel {
  max-width: unset !important;
  min-height: unset !important;
}

.mat-menu-content {
  min-width: unset !important;
  padding: 0px !important;
}

.mat-progress-bar {
  height: 2px !important;
}

.sub-container {
  width: calc(95% - 258px);

  @media only screen and (max-width: $medium-screen) {
    width: calc(95% - 60px);
  }

  @media only screen and (max-width: $phone) {
    width: calc(95% - 20px);
  }
}

button {
  outline: none !important;
}

.mat-step.ng-star-inserted {
  margin-top: 30px;
}

.mat-standard-chip {
  padding: 7px 10px !important;
  min-height: 24px !important;
  font-size: 14px;
}

.info-tooltip {
  color: white;
  font-size: 12px !important;
  line-height: 25px;
}

.mat-tooltip {
  word-break: break-all !important;
  white-space: normal !important;
}

.mat-tab-label {
  opacity: 1 !important;
}

table {
  tr {
    th {
      font-size: 14px !important;
    }
    td {
      font-size: 14px !important;
    }
  }
}

p {
  font-size: 16px;
}

div.empty-box {
  text-align: center;
  font-size: 17px;
  color: #049442;
  width: max-content;
  margin: 115px auto;

  img {
    width: 150px;
    margin-bottom: 20px;
  }
}

mat-form-field {
  font-size: 15px !important;
}

mat-checkbox {
  font-size: 15px !important;
}

mat-selection-list {
  mat-list-option {
    font-size: 15px !important;
  }
}

mat-radio-button {
  font-size: 15px !important;
}

.mat-dialog-title {
  font-size: 18px !important;
}

.hidden {
  visibility: hidden;
}

.file-viewer {
  z-index: 1;
  pointer-events: none !important;
}

.file-viewer.cdk-overlay-dark-backdrop {
  background: rgba(6, 39, 67, 0);
}

.custom-file-container .mat-dialog-container {
  resize: both;
  overflow: auto;
  background: #fff;
  max-width: 100% !important;
  padding-bottom: 0px;
}

.list-option {
  min-height: 18vh;
  max-height: calc(16vh - 154px);
  overflow: auto;
  margin-top: -20px;
  padding: 10px 0px;

  .empty-box {
    margin: 40px auto;

    img {
      width: 60px;
      margin-bottom: 4px;
    }

    h3 {
      font-size: 15px;
    }
  }
}

.decrease-form-field-infix {
  .mat-form-field-infix {
    margin-top: -16px;
  }
}

.display-none {
  display: none !important;
}

.user-chat-img {
  position: absolute;
  bottom: 0;
  width: 34px;
  height: 34px;
  overflow: hidden;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    height: 100%;
    width: auto;
  }
}

.sender {
  margin-right: auto;
  color: black;
  background-color: rgb(241, 241, 241);
  max-width: 400px;

  .img {
    @extend .user-chat-img;
    left: -42px;

    // img {
    //   height: 100%;
    //   width: auto;
    // }
  }
}

.receiver {
  margin-left: auto;
  color: white;
  background-color: rgb(35, 150, 69);
  max-width: 400px;

  .img {
    @extend .user-chat-img;
    right: -42px;

    // img {
    //   width: 100%;
    //   border-radius: 50%;
    // }
  }
}


.img_chat_box {
  padding: 0 !important;

  &:hover {
    cursor: pointer;

    .time {
      visibility: visible;
    }
  }

  .file-box {
    .file-action {
      position: absolute;
      top: 5px;
      right: 5px;
      color: ghostwhite;
    }
  }

  .time {
    position: absolute;
    background: #00000069;
    color: white !important;
    right: 10px;
    bottom: 10px;
    padding: 4px 12px;
    visibility: hidden;
    border-radius: 20px;
  }
}

.pdf_chat_box {
  padding: 0 15px;

  .file-box {
    display: flex !important;
    align-items: center !important;
    padding: 0 8px !important;
    min-width: 280px !important;
    gap: 20px !important;
    height: 60px;
    position: relative;
  
    .file-img {
      background: #2fbd59 !important;
      color: #ffffff !important;
      border-radius: 50% !important;
      padding: 10px !important;
      width: 30px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      transition: .4s ease-in-out;
      position: relative;

      &:hover {
        background: darken($color: #2fbd59, $amount: 3%) !important;
      }
  
      mat-icon {
        font-size: 26px !important;
        // width: 26px !important;
        height: 26px !important;
      }

      mat-spinner {
        position: absolute;
      }
    }
  
    .file-desc {
      flex-grow: 9;
      
      p {
        margin: 8px 0;
        font-size: 14px;
      }
    }

    .file-action {
      position: absolute;
      top: 0px;
      right: -12px;
      color: ghostwhite;
    }

    .file-disabled {
      &.file-img {
        background: #e3e3e3 !important;
        cursor: default !important;
      }
    }
  }

  .time {
    position: absolute;
    right: 10px;
    bottom: 10px;
    padding: 4px 12px;
    border-radius: 20px;
  }
}

.mat-progress-spinner circle,
.mat-spinner circle {
    stroke: #b8b8b8 !important;
}

.desc-col {
	max-width: 200px;
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
	padding-right: 20px;
}

.pl-1 {
  padding-left: 1rem !important;
}

.pl-2 {
  padding-left: 2rem !important;
}

.pl-3 {
  padding-left: 3rem !important;
}