// Colors
$main-color: #5296e6;


$primaryColor: #062743;
$accentColor: #04B160;
$accentLightColor: #04ba69;
$accentDarkColor: #039052;
$accentSuperDarkColor: #87f1a2;
$accentLight: #DDF5EA;
$primaryGrayColor: #5b5b5b;
$backgroundColor: #fff;

$warnColor: #FF8A14;
$rejectColor: #ff4949;
$approveColor: #04b160;
$editedColor: #3373ab;
$pendingColor: #8a8a8a;

$cancel: #e41f1f;

$form-control-color: #286c84 !default;
$form-label-color: #919191 !default;

$fontSmall: 14px;
$fontLarge: 17px;

// Typeface
$fontFamily: unquote('Roboto, Helvetica Neue, Helvetica, Arial, sans-serif, Battambang');

//screen size
$small-phone: 414px;
$phone: 768px;
$ipad-pro: 1024px;
$medium-screen: 1300px;
$large-screen: 1580px;

// Break Point
$isLaptopLowDpi: "(min-width: 1280px) and (max-width: 1335.98px) and (orientation: landscape)";
$isLaptopMediumDpi: "(min-width: 1336px) and (max-width: 1439.98px) and (orientation: landscape)";
$isLaptopHighDpi: "(min-width: 1440px) and (max-width: 1919.98px) and (orientation: landscape)";
$isTablet: '(min-width: 600px) and (max-width: 839.98px) and (orientation: portrait), (min-width: 960px) and (max-width: 1279.98px) and (orientation: landscape)';
$isMobile: '(max-width: 599.98px) and (orientation: portrait), (max-width: 959.98px) and (orientation: landscape)';